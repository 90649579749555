const { HTTP_RESPONSE_STATUS } = useConstants();

export const fetchOrRedirect = async <T, U = Object>(
    path: string,
    query?: Record<string, unknown>,
) => {
    let errorStatusCode;
    let fetchResponse;
    try {
        fetchResponse = await $fetch<{ data: T; meta?: U }>(path, {
            ...query,
            onResponseError({ response }) {
                errorStatusCode = response.status;
                if (errorStatusCode === HTTP_RESPONSE_STATUS.UNAUTHORIZED) {
                    const route = useRoute();
                    navigateTo(
                        `/api/auth/b2b-identity-provider/login?redirectUrl=${route.path}`,
                        { external: true },
                    );
                }
            },
        });
    } catch (error) {
        console.error(error);
    }
    const result: {
        data?: T;
        error: boolean;
        errorStatusCode?: number;
        meta?: U;
    } = {
        data: fetchResponse?.data,
        error: !!errorStatusCode,
        errorStatusCode: errorStatusCode,
    };
    if (fetchResponse && 'meta' in fetchResponse) {
        result['meta'] = fetchResponse.meta;
    }
    return result;
};

export const fetchStatusOrRedirect = async <T>(
    path: string,
    query?: Record<string, unknown>,
) => {
    let hasError = false;
    try {
        await $fetch<{ data: T }>(path, {
            ...query,
            onResponseError({ response }) {
                hasError = true;
                if (response.status === HTTP_RESPONSE_STATUS.UNAUTHORIZED) {
                    const route = useRoute();
                    navigateTo(
                        `/api/auth/b2b-identity-provider/login?redirectUrl=${route.path}`,
                        { external: true },
                    );
                }
            },
        });
    } catch (error) {
        console.error(error);
    }
    return !hasError;
};

export const fetchExceptionsOrRedirect = async <T>(
    path: string,
    query?: Record<string, unknown>,
) => {
    let hasError = false;
    let exceptions: Array<string> = [];
    try {
        await $fetch<{ data: T }>(path, {
            ...query,
            onResponseError({ response }) {
                hasError = true;
                const errors = response._data?.data?.errors;
                if (errors?.length) {
                    exceptions = errors
                        .map(
                            (error: { meta?: { exception?: string } }) =>
                                error.meta?.exception,
                        )
                        .filter((exception?: string) => exception != null);
                }
                if (response.status === HTTP_RESPONSE_STATUS.UNAUTHORIZED) {
                    const route = useRoute();
                    navigateTo(
                        `/api/auth/b2b-identity-provider/login?redirectUrl=${route.path}`,
                        { external: true },
                    );
                }
            },
        });
    } catch (error) {
        console.error(error);
    }
    return {
        error: hasError,
        exceptions: exceptions,
    };
};
