const HTTP_RESPONSE_STATUS = {
    FORBIDDEN: 403,
    UNAUTHORIZED: 401,
} as const;

export const useConstants = () => {
    return {
        EMAIL_PATTERN: /(.+)@(.+)/,
        HTTP_RESPONSE_STATUS,
        MAX_CHARACTERS_DEFAULT: 5000,
        MAX_CHARACTERS_NOTE: 1000,
        MAX_CHARACTERS_TITLE: 150,
        MAX_EMAILS_SHARE_TALENT: 5,
        MAX_FILTER_SALARY: 100000,
        MAX_SAVED_SEARCH_KEYWORD: 30,
        PRODUCT_TYPE_CONTINGENT: 'talent-contacts-limited-',
        PRODUCT_TYPE_MULTI: 'talent-contacts-unlimited-multi',
        PRODUCT_TYPE_POWER: 'talent-contacts-unlimited-power',
        PRODUCT_TYPE_SINGLE: 'talent-contacts-unlimited-single',
    };
};
